.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.app-container {
  width: 100%; 
  text-align: right
}
.text-center {
  text-align: right
}
.margin5{
  margin: 5px
}
.hide{
  display: none;
}
.show{
  display: block;
}

.download-button {
  text-align: center;
}

.form-body-container {
  max-width: 800px;
  width: 90%;
  margin: auto;
}

.login-button-container {
  text-align: center;
}

.login-button-title {
  color: #006a4d;
}

.login-button-style {
  border: none;
  background-color:#006A4D;
  color: #fff;
  padding: 10px 35px;
  border-radius: 5px;
  font-size: 18px;
  margin-top: 15px;
}

.member-login-container {
  margin-bottom: 25px;
  border-bottom: 1px dashed #cccccc;
  padding-bottom: 30px;
}
.topline{width:100%;height:2px;background:#4FC6E1;margin:20px 0;}
.hydrated{background: #aaa;}
body,.hydrated{  background: #f5f6f8;
   height: 100%; }
.forgot-password {
  margin: 5% auto;

  display: table;
 }
 .hidebtn{ 
  display: none;
  width: 151px;
    height: 39px;
    margin-top: -12%;
    margin-left: 36%;
      position: absolute;
      z-index: 9999;
      background: #fff;}
:root {
  --amplify-primary-color: #003f2d;
  --amplify-primary-tint: #003f2b;
  --amplify-primary-shade: #003f2d;
}
.sign-up-form-footer {
  margin-top: 80px !important;
}
.logo{text-align: center;width:100%;position: absolute;   left:0; top: -70px;}
.logo img{width: 200px;}
#cbre-login a,#xieyi a{color:#003f2d; cursor: pointer;}
#cbre-login{
  position: absolute;
  top: 415px;
  border-top: 1px solid #eee;
  z-index: 999;
  width: 82%;
  left: 9%;
}

@media screen and (max-width:671.5px) {
  #cbre-login{
    top: 440px;
  }
  
}
#xieyi{    margin-bottom: 15px;}
#cbre-login a:hover{text-decoration: underline;}
#usernamealt{margin-bottom: 5px;
  font-size: 12px;
  margin-left: 110px;}
#passwordalt{    margin-bottom: -144px;
  margin-top: 110px;
  margin-left: 74px;
  font-size: 12px;}
  div.sign-in-form-footer{margin-bottom: 50px !important;}

  .input-host{
    position: relative;
    height: 60px;
    border-radius: 5px;
    box-shadow: inset 5px 5px 10px rgba(204, 197, 197,.5),
                inset -5px -5px 8px rgba(204, 197, 197,.5);
}
.input-host input{
  position: absolute;
  top: 0;
  left: 20px;
  height: 100%;
  width: 300px;
  font-size: 18px;
  outline: none;
  border: none;
  background-color:transparent;
}
.kuang input::placeholder{
  color: rgba(68, 67, 67,.8);
}
.conceal{
  position: absolute;
  top: 245px;
  z-index: 2;
  right: 45px;
  width: 30px;
  height: 30px;
  background-image: url(assets/images/show.png);
  background-size: 100% 100%;   
  cursor: pointer;
}
.conceal.yinchang{
  background-image: url(assets/images/hide.png);
  background-size: 100% 100%; 
}
.conceal2{
  position: absolute;
  top: 572px;
  z-index: 2;
  right: 45px;
  width: 30px;
  height: 30px;
  background-image: url(assets/images/show.png);
  background-size: 100% 100%;   
  cursor: pointer;
}
.conceal2.jp{
  top: 558px;
}
.conceal2.yinchang{
  background-image: url(assets/images/hide.png);
  background-size: 100% 100%; 
}

.conceal3{
  position: absolute;
  display: none;
  z-index: 2;
  top: 268px;
  left: 60%;
  width: 30px;
  height: 30px;
  background-image: url(assets/images/show.png);
  background-size: 100% 100%;   
  cursor: pointer;
}

.conceal3.yinchang{
  background-image: url(assets/images/hide.png);
  background-size: 100% 100%; 
}

#queren{
  position: absolute;
  display: none;
  z-index: 2;
  top: 50px;
  left: 3%;
  width: 380px;
}
#root > div > div{position: relative;}

